import React from 'react'
import { Link, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import SEO from '../components/seo'
import Bio from '../components/Bio'
import Layout from '../components/Layout'
import './blog-list.css'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={siteTitle}
          keywords={[`blog`, `suprada`, `ropeandtire`, `learnings`]}
        />
        <div>
          <Link to="/tags">Browse by Tags</Link>
        </div>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const url = node.frontmatter.url

          return (
            <div key={url} className="blog-listing">
              <h3 className="blog-title">
                <Link to={url}>{title}</Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <p
                className="blog-excerpt"
                dangerouslySetInnerHTML={{ __html: node.excerpt }}
              />
            </div>
          )
        })}
        <ul className="bloglist-nav-container">
          {!isFirst ? (
            <Link to={`/${prevPage}`} rel="prev" className="bloglist-prev">
              <FontAwesomeIcon icon={faChevronLeft} /> Previous Page
            </Link>
          ) : (
            <span></span>
          )}
          <span className="bloglist-nav-numbers">
            {Array.from(
              { length: numPages },
              (_, i) =>
                // Show only 5 pages, followed by three dots and the last page
                (i === 0 || // always show the page 1 link
                  i === currentPage || // show page 2
                  (i > currentPage && i < currentPage + 6) || // show next 6 pages
                  i === numPages - 1 || // show last page
                  i === currentPage - 1) && (
                  <li
                    key={`pagination-number${i + 1}`}
                    className="bloglist-pagination-item"
                  >
                    {i === numPages - 1 && ' . . .  '}
                    <Link
                      to={`/${i === 0 ? '' : i + 1}`}
                      className="bloglist-pagination-link"
                      style={{
                        color: i + 1 === currentPage ? '#3b4773' : '',
                        background: i + 1 === currentPage ? '#e9eef2' : '',
                      }}
                    >
                      {i + 1}
                    </Link>
                    {i === 0 && currentPage > 1 && '  . . .'}
                  </li>
                )
            )}
          </span>
          {!isLast ? (
            <Link to={`/${nextPage}`} rel="next" className="bloglist-next">
              Next Page <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          ) : (
            <span></span>
          )}
        </ul>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            url
          }
        }
      }
    }
  }
`
